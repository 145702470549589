export const DEFAULT_IMAGE_ICON_SIZE = 33;

export const THUMBNAIL_WIDTH = 60;

export const NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE = 0;

export const MAX_ITEM_OPTION_LENGTH = 500;

export const SPECS = {
  DontRedirectToSiteOnFailedFetch: 'specs.stores.DontRedirectToSiteOnFailedFetch',
  CheckoutExcludeGoogleAutoComplete: 'specs.stores.CheckoutExcludeGoogleAutoComplete',
  ShouldFetchCustomSettingsAndCustomizeCheckoutLayout:
    'specs.stores.ShouldFetchCustomSettingsAndCustomizeCheckoutLayout',
  ShouldSplitBillingInfoPrefill: 'specs.stores.ShouldSplitBillingInfoPrefill',
  SlotSpacingAndDividers: 'specs.stores.SlotSpacingAndDividers',
  HideBillingFormForPayPalAndManualNotBrazil: 'specs.stores.HideBillingFormForPayPalAndManualNotBrazil',
  EnablePlaceOrderButtonForExistingNoAddressCustomer: 'specs.stores.EnablePlaceOrderButtonForExistingNoAddressCustomer',
  WithCheckoutHackForReact18Tests: 'specs.stores.WithCheckoutHackForReact18Tests',
  GiftCartSeviceDownCheckout: 'specs.stores.GiftCartSeviceDownCheckout',
  UseUndefinedAsDefaultBillingAddressInCheckout: 'specs.stores.UseUndefinedAsDefaultBillingAddressInCheckout',
  MoveSlotAboveCheckoutButton: 'specs.stores.MoveSlotAboveCheckoutButton',
  ShowExpressCheckoutButtonsOnInitialStep: 'specs.stores.ShowExpressCheckoutButtonsOnInitialStep',
  UseCheckoutHeaderOnCheckout: 'specs.stores.UseCheckoutHeaderOnCheckout',
  ShowVatIdOnBillingForIsrael: 'specs.stores.ShowVatIdOnBillingForIsrael',
  AddLayoutConfigToLineItemsAutoGql: 'specs.stores.AddLayoutConfigToLineItemsAutoGql',
  ShouldAddLocaleToDatePicker: 'specs.stores.ShouldAddLocaleToDatePicker',
  ShouldHandlePaylinksRedirectError: 'specs.stores.ShouldHandlePaylinksRedirectError',
  ShouldFocusCheckboxesOnError: 'specs.stores.ShouldFocusCheckboxesOnError',
  ShouldRedesignCouponsSection: 'specs.stores.ShouldRedesignCouponsSection',
  shouldChangeToBiCatalogV2: 'specs.stores.shouldChangeToBiCatalogV2',
  UseNewSubscriptionCheckboxInCustomerDetailsForm: 'specs.stores.UseNewSubscriptionCheckboxInCustomerDetailsForm',
  MigrateSubscriptionToCheckoutForm: 'specs.stores.MigrateSubscriptionToCheckoutForm',
  FixCustomPolicyLongText: 'specs.stores.FixCustomPolicyLongText',
  ShouldCheckViolationsOnUpdateCheckout: 'specs.stores.ShouldCheckViolationsOnUpdateCheckout',
  AddSlotToCheckoutPolicies: 'specs.stores.AddSlotToCheckoutPolicies',
  RemoveOpacityGetFunctions: 'specs.stores.RemoveOpacityGetFunctions',
  TestNewSubscriptionCheckboxLocation: 'specs.stores.TestNewSubscriptionCheckboxLocation',
  SupportDeliveryViolationsOnCheckout: 'specs.stores.SupportDeliveryViolationsOnCheckout',
  UseNewSubscriptionView: 'specs.stores.UseNewSubscriptionView',
  FixClientBIForCardTokenization: 'specs.stores.FixClientBIForCardTokenization',
  AddNewSubscriptionFieldsToCheckoutQuery: 'specs.stores.AddNewSubscriptionFieldsToCheckoutQuery',
  AddPoliciesToLineItems: 'specs.stores.AddPoliciesToLineItems',
  SupportFreeTrialLineItems: 'specs.stores.SupportFreeTrialLineItems',
  CheckoutFromSDKInExpressCheckoutButtons: 'specs.stores.CheckoutFromSDKInExpressCheckoutButtons',
  CheckMemberAreaInstalledV3: 'specs.stores.CheckMemberAreaInstalledV3',
};
