import {errorInCheckoutParams} from '@wix/bi-logger-ecom-platform-data/v2/types';
import {CheckoutModel} from '../models/checkout/Checkout.model';
import {ItemTypePreset} from '../models/checkout/ItemType.model';
import {CheckoutSettingsModel} from '../models/checkout/checkoutSettings/CheckoutSettings.model';
import {CheckoutErrorCode} from './errors';
import {CartType} from '@wix/wixstores-client-core';
import {StepId} from '../../types/checkoutApp.types';
import {PaymentMethod} from '@wix/wixstores-graphql-schema-node';
import {PaymentMethod as cashierPaymentMethod} from '@wix/cashier-payments-widget';
import {PaymentsWidgetBIData} from '@wix/cashier-payments-widget/dist/src/types/BI';
import {ViolationModel} from '../models/checkout/Violation.model';
import {calcVisibleViolations} from './violations.utils';
import {SelectedShippingOptionModel} from '../models/checkout/SelectedShippingOption.model';
import {getUniques, toBiAmount} from './common/bi.util.common';

export enum FlowType {
  fastFlow = 'fast flow',
  fullFlow = 'full flow',
}

export enum ShippingMethodType {
  pickup = 'pickup',
  nonPickup = 'non-pickup',
  shipping = 'shipping',
  none = 'none',
}

export enum StepName {
  PLACE_ORDER_FAST_FLOW = 'PlaceOrderFastFlow',
  SHIPPING_DETAILS = 'ShippingDetails',
  ONLOAD_OR_UPDATE_FAST_FLOW = 'OnLoadOrUpdateFastFlow',
}

export enum BiAddressActionOrigin {
  UserIntent = 'user-intent',
  AddressError = 'address-error',
}

export enum BiFoldableSummaryAction {
  open = 'open',
  close = 'close',
}

export enum BiMobilePosition {
  top = 'Top',
  bottom = 'Bottom',
}

export const ECOM_PLATFORM_CHECKOUT = 'ecom-platform-checkout';

export const cantShipToDestinationBaseParams = (): Partial<errorInCheckoutParams> => {
  return {
    stage: StepName.SHIPPING_DETAILS,
    field: 'canShipToDestination',
    errorMessage: 'cannot ship to destination',
  };
};

export const getCatalogAppIds = (checkout: CheckoutModel): string => {
  return getMappedUniqueList(checkout.lineItems, ({catalogAppId}) => catalogAppId);
};

export const getPaymentsWidgetBIData = (checkout: CheckoutModel): PaymentsWidgetBIData => {
  return {
    purchaseFlowId: checkout.purchaseFlowId,
    catalogAppId: getCatalogAppIds(checkout),
  };
};

export const getCustomFieldsTitles = (checkout: CheckoutModel): string | undefined => {
  return checkout.customField?.title;
};

export const isCustomFieldMandatory = (checkoutSettings: CheckoutSettingsModel): boolean => {
  return checkoutSettings.customField.mandatory;
};

export const getItemTypes = (checkout: CheckoutModel): string => {
  return getMappedUniqueList(checkout.lineItems, ({itemType}) =>
    itemType?.preset ? itemType?.preset.toString() : itemType?.custom
  );
};

export const getCouponErrorCodeForBi = (code: string): string => {
  switch (code) {
    case CheckoutErrorCode.ERROR_INVALID_COMMAND_FIELD:
      return 'ERROR_INVALID_COMMAND_FIELD_FOR_COUPON';
    case CheckoutErrorCode.ERROR_INVALID_SUBTOTAL:
      return 'ERROR_INVALID_SUBTOTAL_FOR_COUPON';
    case CheckoutErrorCode.ERROR_INVALID_PRODUCTS:
      return 'ERROR_INVALID_PRODUCTS_FOR_COUPON';
    case CheckoutErrorCode.ERROR_INVALID_PRODUCT_QUANTITY:
      return 'ERROR_INVALID_PRODUCT_QUANTITY_FOR_COUPON';
    case CheckoutErrorCode.ERROR_NOT_APPLICABLE_FOR_SUBSCRIPTIONS:
      return 'ERROR_NOT_APPLICABLE_FOR_SUBSCRIPTIONS_FOR_COUPON';
    default:
      return code;
  }
};

export const getShippingOptionTypes = (checkout: CheckoutModel): string => {
  return getMappedUniqueList([...checkout.shippingOptions, ...checkout.pickupOptions], ({title}) => title);
};

export const getSelectedShippingOptionIndex = (checkout: CheckoutModel): number | undefined => {
  const isSelectedOption = ({code}: {code: string}) => code === checkout.selectedShippingOption?.code;

  const shippingOptionIndex = checkout.shippingOptions.findIndex(
    (option) =>
      isSelectedOption(option) || /* istanbul ignore next */ option.scheduledDeliveryOptions?.some(isSelectedOption)
  );

  if (shippingOptionIndex !== -1) {
    return shippingOptionIndex;
  }

  const pickupOptionIndex = checkout.pickupOptions.findIndex(isSelectedOption);

  return pickupOptionIndex !== -1 ? pickupOptionIndex + checkout.shippingOptions.length : /* istanbul ignore next */ -1;
};

export const hasPickupOption = (checkout: CheckoutModel): boolean => {
  return checkout.pickupOptions.length > 0;
};

export const isFullyPaidByGiftCard = (checkout: CheckoutModel): boolean => {
  return Boolean(
    checkout.giftCard?.price?.amount &&
      checkout.giftCard?.price?.amount > 0 &&
      checkout.payNowTotalAfterGiftCard.amount === 0
  );
};

function getMappedUniqueList<T>(arr: T[] | undefined, mapper: (t: T) => string | undefined): string {
  return getUniques((arr ?? /* istanbul ignore next */ []).map(mapper).filter(isDefined) as string[]).toString();
}

export const getAdditionalFeesPrice = (checkout: CheckoutModel): number => {
  return toBiAmount(checkout.priceSummary?.additionalFees.amount ?? /* istanbul ignore next */ 0);
};

export const getNumberOfAdditionalFees = (checkout: CheckoutModel): number => {
  return checkout.additionalFees?.length ?? /* istanbul ignore next */ 0;
};

export const getValidations = (
  violations: ViolationModel[],
  supportDeliveryViolationsOnCheckout: boolean
): string | undefined => {
  if (violations.length === 0) {
    return undefined;
  }

  const visibleViolations = calcVisibleViolations(violations);
  const biViolations = visibleViolations.map((violation) => {
    return {
      type: violation.severity,
      is_line_item: Boolean(violation.target?.lineItemTarget?.id),
      message: violation.description,
      ...(supportDeliveryViolationsOnCheckout && {
        name: violation.target?.otherTarget?.name || violation.target?.lineItemTarget?.name,
        suggestedFix: violation.target?.lineItemTarget?.suggestedFix,
      }),
    };
  });

  return JSON.stringify(biViolations);
};

export const getPaymentCategory = (paymentMethod?: string) => {
  switch (paymentMethod) {
    case cashierPaymentMethod.Offline:
      return 'Unknown';
    // istanbul ignore next
    case cashierPaymentMethod.CreditCard:
      return 'CreditCard';
    // istanbul ignore next
    default:
      return 'Ewallet';
  }
};

export const getCartType = ({lineItems}: CheckoutModel): CartType => {
  const hasDigitalItems = lineItems?.some((lineItem) => lineItem.itemType?.preset === ItemTypePreset.DIGITAL);
  const hasPhysicalItems = lineItems?.some((lineItem) => lineItem.itemType?.preset === ItemTypePreset.PHYSICAL);
  const hasServiceItems = lineItems?.some((lineItem) => lineItem.itemType?.preset === ItemTypePreset.SERVICE);
  const hasGiftCardItems = lineItems?.some((lineItem) => lineItem.itemType?.preset === ItemTypePreset.GIFT_CARD);

  if (hasDigitalItems && hasPhysicalItems) {
    return CartType.MIXED;
  }

  if (hasDigitalItems) {
    return CartType.DIGITAL;
  }

  if (hasPhysicalItems) {
    return CartType.PHYSICAL;
  }

  if (hasServiceItems) {
    return CartType.SERVICE;
  }

  if (hasGiftCardItems) {
    return CartType.GIFT_CARD;
  }

  return CartType.UNRECOGNISED;
};

const isDefined = <T>(obj: T): boolean => {
  return !!obj;
};

export const getFlowType = (isFastFlow: boolean): FlowType => {
  if (isFastFlow) {
    return FlowType.fastFlow;
  } else {
    return FlowType.fullFlow;
  }
};

export const getFirstStage = (isFastFlow: boolean, firstStage: StepId): StepId => {
  if (isFastFlow) {
    return StepId.paymentAndPlaceOrder;
  } else {
    return firstStage;
  }
};

export const getSelectedShippingMethodType = (
  selectedShippingOption?: SelectedShippingOptionModel
): ShippingMethodType => {
  let shippingMethodType = ShippingMethodType.none;

  if (selectedShippingOption) {
    shippingMethodType = selectedShippingOption.isPickup
      ? ShippingMethodType.pickup
      : /* istanbul ignore next */ ShippingMethodType.shipping;
  }

  return shippingMethodType;
};

export const isWithOfflinePaymentMethod = (activePaymentMethods?: PaymentMethod[]): boolean => {
  return Boolean(activePaymentMethods?.find((paymentMethod) => paymentMethod.name === cashierPaymentMethod.Offline));
};

export const hasSavedAddress = (checkout: CheckoutModel): boolean => {
  return Boolean(checkout.billingInfo);
};

export const getNumOfShippingOptions = (checkout: CheckoutModel): number => {
  return checkout.shippingOptions.length + checkout.pickupOptions.length;
};
